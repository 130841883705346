import {SetSecurityAction} from "@js/actions/loginActions";

import {SET_SECURITY} from "@js/actionTypes/loginActionTypes";

/**
 * @const setSecurity
 */
export const setSecurity = (token: string, expireDate: string, firstName: string, lastname: string, username: string): SetSecurityAction => ({
    payload: {
        token: token,
        expireDate: expireDate,
        firstName: firstName,
        lastName: lastname,
        username: username
    },
    type: SET_SECURITY
});
