import React from "react";

import { Helmet } from "react-helmet";

import { navigate } from "gatsby"

import { useEffect } from "react";
import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useState } from "react";

import Box from "@js/component/Box";
import Heading from "@js/component/Heading";
import LinkButton from "@js/component/LinkButton";
import Main from "@js/component/Main";

import StoreState from "@js/store/StoreState";

import { isLoggedIn } from "@js/services/auth";
import { setSecurity } from "@js/actionCreators/loginActionCreators";

import * as classNames from "@css/component/template/portal/Logout.module.scss";


/**
 * @const Logout
 */
const Logout = () => {
    const dispatch = useDispatch();

    const [timeoutId, setTimeoutId] = useState(-1);

    const token = useSelector((storeState: StoreState) => storeState.security.token);

    const logOut = async () => {
        const response = await fetch(`${ process.env.GATSBY_API_ADVISOR_URL }/logout?token=${ token }`);

        dispatch(setSecurity("", "", "", "", ""));

        setTimeoutId(setTimeout(() => {
            navigate("/", { replace: true });
        }, 2000) as unknown as number);
    };

    useLayoutEffect(() => {
        if (!(isLoggedIn())) {
            navigate("/portaal/login", { replace: true });
        }
    }, []);

    useEffect(() => {
        logOut();

        return () => {
            clearInterval(timeoutId);
        }
    }, []);

    return (
        <Main className={ classNames.main }>
            <Helmet>
                <title>Uitloggen</title>
            </Helmet>
            <div className={ classNames.container }>
                <section className={ classNames.logoutPage }>
                    <Box className={ classNames.box }>
                        <header className={ classNames.header }>
                            <Heading element="h1" variant="large">
                                Je bent <strong>uitgelogd</strong>
                            </Heading>
                        </header>
                        <span className={ classNames.text }>
                            Je bent succesvol uitgelogd.
                        </span>
                        <LinkButton to="/">Naar de homepagina</LinkButton>
                    </Box>
                </section>
            </div>
        </Main>
    );
};

export default Logout;
